/* General Styles  - powered by Procrastinate Pixels - www.procrastinatepixels.com */

@font-face {
  font-family: 'DK Cool Crayon';
  src: url('/public/fonts/DKCoolCrayon/DKCoolCrayon.woff2') format('woff2'),
      url('/public/fonts/DKCoolCrayon/DKCoolCrayon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.app-wrapper {
  min-width: 320px;
  /* Minimum width as before */
  min-height: 600px;
  /* Set a minimum height - adjust as needed */
  overflow: hidden;
  /* Enable both vertical and horizontal scrollbars when needed */
  position: relative;
  /* Ensure proper positioning of child elements */
  font-family: DK Cool Crayon, sans-serif;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  min-width: 320px;
  /* Same as .app-wrapper */
  min-height: 600px;
  /* Same as .app-wrapper */
  overflow: hidden;
  /* Prevent scrolling on the body */
}

/* Background Styles */
.background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* Ensure it's behind other content */
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* Full-page background */
.landing-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  position: relative;
}

/* Logo image styling */
.logo-image {
  width: 100%;
  /* Same width as the container */
  height: auto;
  border: none !important;
  /* Ensure no border */
  padding: 0 !important;
  /* Ensure no padding */
  margin: 0 !important;
  /* Ensure no margin */
  box-shadow: none !important;
  /* Disable any potential box-shadow */
  background: none !important;
  /* Ensure no background is applied */
}

/* Logo styles for FrontPage and Gallery */
.frontpage-logo,
.gallery-logo,
.results-logo {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25%;
  /* Adjust this size */
  z-index: 2;
}

.frontpage-logo .logo-image,
.gallery-logo .logo-image,
.results-logo .logo-image {
  width: 100%;
  height: auto;
  border: none;
  padding: 0;
  margin: 0;
  box-shadow: none;
  background: none;
}

/* Mouth image positioned in the center */
.mouth-container {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 100%;
  height: 60vh;
  transform: translate(-50%, -50%);
}



/* Centralize text within the input and style */
.gallery-search-input {
  width: 60%;
  height: 20%;
  padding: 10px;
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 90px; /* Slightly more than half of the button's height */
  text-align: center;
  /* Center input text */
  font-family: DK Cool Crayon, sans-serif;
  color: rgb(31, 30, 30);
  border: 2px transparent #ccc;
  background-color: #f8f8f86e;
  border-radius: 8px;
}

/* Highlight invalid input */
.gallery-search-input.invalid {
  color: rgba(255, 255, 255, 0.4);
  /* Red text when invalid */
  border: 2px transparent #ccc;
}

.gallery-search-input:focus {
  outline: none;
  border: 2px transparent #ccc;
}

.search-container {
  position: relative;
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-holder {
  width: 100%;
  max-width: 500px; /* Adjust as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.gallery-search-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #2c2b2b;
}
.gallery-search-input::-moz-placeholder { /* Firefox 19+ */
  color: #2c2b2b;
}
.gallery-search-input:-ms-input-placeholder { /* IE 10+ */
  color: #2c2b2b;
}
.gallery-search-input:-moz-placeholder { /* Firefox 18- */
  color: #2c2b2b;
}
.gallery-search-input::placeholder {
  color: #2c2b2b;
}

/* Search button styling */
.search-button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: 80px;
  background: url('/public/assets/search2.png') no-repeat center center;
  background-size: contain;
  z-index: 2;
  bottom: 10px; /* Half of the button's height */
}

.search-button:hover {
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.8));
}

/* Gallery button */
.gallery-button {
  position: relative;
  top: 35%;
  left: 0px;
  width: 300px;
  height: 300px;
  background: url('/public/assets/ENTER.png') no-repeat center center;
  background-size: contain;
  z-index: 2;
}

.gallery-button:hover {
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.8));
}

/* Gallery container */
.gallery-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  overflow: hidden;
  margin-top: 10%;
}

.swiper {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container {
  width: 90%;
  max-width: 485px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gallery-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: top;
  border: 1px solid #e3c6ad;
  box-shadow: 25px 25px 25px rgba(0, 0, 0, 0.6);
  border-radius: 15px;
}

.image-container p {
  font-size: 16px;
  color: white;
  margin-top: 5px;
}

.result-container {
  font-family: DK Cool Crayon, sans-serif;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.result-container img {
  width: 30%;
  height: 30%;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 15px;
  border: 2px solid #ddd;
}

.info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 40%;
  right: 15%;
  font-size: 300%;
}

.info-container h2,
.info-container p {
  background-color: rgba(0, 0, 0, 0.5);
  font-family: DK Cool Crayon, sans-serif;
  color: white;
  background-color: #f8f8f800;
  border-radius: 8px;
  padding: 10px;
  text-align: center;

  width: 70%;
  height: auto;
  margin-left: 20px;
  margin-right: 20px;
}

.images-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}

/* Logo container */
.logo-container {
  top: -7%;
  left: 0%;
  width: 30%;
}

/* Adjust the text position */
.logo-text {
  font-size: 3vw;
  /* Scale text size based on viewport width */
  color: white;
  font-family: DK Cool Crayon, sans-serif;
  text-align: center;
  margin-top: -45%;
  /* Move the text up */
  margin-left: 33%;
  /* Move the text left */
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.modal-content h2 {
  margin-bottom: 15px;
}

.modal-content button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
}

.modal-content button:hover {
  background-color: #0056b3;
}

model-viewer::part(progress-bar) {
  display: none;
  /* Hides the progress bar */
}

model-viewer::part(default-progress-bar) {
  display: none;
  /* Hides the default progress bar */
}

@media only screen and (max-width: 430px) {

  .mouth-container {
    top: 35%;
  }

  /* Logo container */
  .logo-container {
    top: -6%;
    left: 10%;
    width: 80%;
    /* Ensure this matches the size in ResultPage */
  }

  .gallery-button {
    position: relative;
    top: 30%;
  }

  .gallery-image {
    width: 90%;
    margin: 40px auto 0; /* This centers the image horizontally and keeps the top margin */
    object-fit: contain;
    object-position: top;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.6);
    display: block; /* Ensures the margin auto works for centering */
  }

  .search-container {
    top: -20px;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    margin-top: 30px; /* This moves the search container down by 30px */
  }

  .search-holder {
    margin-bottom: 0px;
  }

  .images-container {
    margin-top: 100px;
    flex-direction: column;
    gap: 15px;
  }

  .images-container img {
    width: 50%;
  }

  html.result-page .logo-text {
    margin-top: -44%;
    margin-left: 35%;
    font-size: 10vw;
  }

  .square-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: top;
    border: 1px solid #e3c6ad;
    box-shadow: 25px 25px 25px rgba(0, 0, 0, 0.6);
    border-radius: 15px;
  }

  .round-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: top;
    border: 1px solid #e3c6ad;
    box-shadow: 25px 25px 25px rgba(0, 0, 0, 0.6);
    border-radius: 15px;
  }

  .swiper {
    margin-top: 100px;
  }

  .search-button {
    bottom: -25px; /* Adjust this value as needed */
  }


}

@media only screen and (min-width: 1650px) {

  .logo-container {
    top: -7%;
    left: 0%;
    width: 20%;
  }
}

.background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* Ensure it's behind other content */
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* 16:9 aspect ratio screens */
@media only screen and (min-aspect-ratio: 4/3) {
  .gallery-container {
    margin-top: 0%;
    /* Adjust top margin for gallery */
  }



  .gallery-image {
 
    object-fit: contain;
    object-position: top;
  }

  .mouth-container {
    top: 45%;
    /* Center the mouth container vertically */
    height: 70vh;
    /* Adjust height to fit 16:9 better */
  }


  .images-container {
    margin-top: 125px;
  }

  /* Add any other adjustments needed for 16:9 screens */
}

/* Lock orientation to portrait on phones */
@media screen and (max-width: 767px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 1024px) and (orientation: landscape) {
  .app-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .orientation-message {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 1);
    color: white;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    font-size: 1.5em;
    text-align: center;
    padding: 20px;
  }
}
